// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rw d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_rx d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_ry d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_rz d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_rB d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_rC d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_rD d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rF t_rF";
export var heroExceptionNormal = "s_rG t_rG";
export var heroExceptionLarge = "s_rH t_rH";
export var Title1Small = "s_rJ t_rJ t_rQ t_rR";
export var Title1Normal = "s_rK t_rK t_rQ t_rS";
export var Title1Large = "s_rL t_rL t_rQ t_rT";
export var BodySmall = "s_rM t_rM t_rQ t_r7";
export var BodyNormal = "s_rN t_rN t_rQ t_r8";
export var BodyLarge = "s_rP t_rP t_rQ t_r9";